<template>
  <div
    class="kanji-card flex transition"
  >
    <div id="test"/>
    <div class="kanji-card__draw-section">
      <div @click="$emit('view-detail', kanji.id)"
           :id="`kanji_list_item_${kanji.id}`"
           class="kanji-card__character cursor-pointer"
      >
        <div
        ></div>
        {{ kanji.character }}
      </div>

      <div
        v-if="kanji.jlpt_level"
        class="kanji-card__jlpt_level"
      >
        JLPT {{ kanji.jlpt_level }}
      </div>

      <div
        class="kanji-card__strokes"
      >
        <span>
          {{ kanji.strokes }} nét
        </span>
        <button @click="draw">
          <i class="fas fa-play-circle" />
        </button>
      </div>

    </div>

    <div class="kanji-card__text-section">
      <router-link
        v-if="showRadical"
        class="kanji-card__radical"
        :to="{ name: 'KanjiRadicalShow', params: { character: kanji.kanji_radical_character }}"
      >
        Bộ {{ kanji.kanji_radical_name_vi }}
      </router-link>

      <div @click="$emit('view-detail', kanji.id)"
           class="cursor-pointer"
      >
        <div class="">
        <span class="kanji-card__name_vi">
          {{ kanji.name_vi }}
        </span>

          <span
            v-if="kanji.description_vi"
            class="kanji-card__description_vi"
          >
          ({{ kanji.description_vi }})
        </span>
        </div>

        <div
          v-if="kanji.on_yomi"
          class="kanji-card__on-yomi"
        >
          {{ kanji.on_yomi }}
        </div>
        <div
          v-if="kanji.kun_yomi"
          class="kanji-card__kun-yomi"
        >
          {{ kanji.kun_yomi }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const props = withDefaults(defineProps<{
  kanji: object
  showRadical?: boolean
}>(), {
  showRadical: true
})

function draw() {
  document.getElementById(`kanji_list_item_${props.kanji.id}`).innerText = ''
  var dmak = new Dmak(props.kanji.character, {
    uri: '/characters/',
    step: 0.012,
    element : `kanji_list_item_${props.kanji.id}`,
    stroke: {
      order: {
        visible: true,
        attr: {
          'font-size': 12,
        }
      },
      attr: {
        stroke: 'random'
      }
    },
  });
}
</script>

<style lang="sass" scoped>
.kanji-card
  background: #fafafa !important
  box-shadow: 0 0 10px rgba(0,0,0,0.3), 0 0 300px 25px rgba(255,255,235,0.8) inset !important
</style>
